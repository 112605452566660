import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LineItem from 'src/pages/line-item/[uuid]';
import PrivateRoute from 'src/routes/PrivateRoute';

import NotFoundPage from '../pages/not-found';

const LineItemRoutes = () => (
  <Routes>
    <Route index path='/:uuid' element={<PrivateRoute Component={LineItem} />} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default LineItemRoutes;
