import {
  IMPERIAL_UNITS,
  MATERIAL_UNITS,
  MEASUREMENT_SYSTEM as MEASUREMENT_UNITS_SETTINGS,
  METRIC_UNITS,
} from 'src/utils/constants';

export function convertToUserUnits(quantity, units) {
  const measurementSystem =
    JSON.parse(window.localStorage.getItem('measurementSystem')) ||
    MEASUREMENT_UNITS_SETTINGS.IMPERIAL;

  const isMetricUnit = METRIC_UNITS.includes(units);
  const isImperialUnit = IMPERIAL_UNITS.includes(units);

  if (
    (measurementSystem === MEASUREMENT_UNITS_SETTINGS.METRIC && isMetricUnit) ||
    (measurementSystem === MEASUREMENT_UNITS_SETTINGS.IMPERIAL && isImperialUnit)
  ) {
    // No conversion needed
    return { quantity, units };
  } else {
    // Conversion needed
    return performConversion(quantity, units, measurementSystem);
  }
}

const performConversion = (quantity, units, measurementSystem) => {
  const toMetricConversion = {
    [MATERIAL_UNITS.LB]: {
      factor: 0.453592, // Pounds to kilograms
      unit: MATERIAL_UNITS.KG,
    },
    [MATERIAL_UNITS.INCH3]: {
      factor: 16.3871, // Cubic inches to cubic centimeters
      unit: MATERIAL_UNITS.CM3,
    },
  };

  const toImperialConversion = {
    [MATERIAL_UNITS.GRAM]: {
      factor: 0.00220462, // Grams to pounds
      unit: MATERIAL_UNITS.LB,
    },
    [MATERIAL_UNITS.KG]: {
      factor: 2.20462, // Kilograms to pounds
      unit: MATERIAL_UNITS.LB,
    },
    [MATERIAL_UNITS.CM3]: {
      factor: 0.0610237, // Cubic centimeters to cubic inches
      unit: MATERIAL_UNITS.INCH3,
    },
    [MATERIAL_UNITS.MM3]: {
      factor: 6.10237e-5, // Cubic millimeters to cubic inches
      unit: MATERIAL_UNITS.INCH3,
    },
    [MATERIAL_UNITS.L]: {
      factor: 61.0237, // Liters to cubic inches
      unit: MATERIAL_UNITS.INCH3,
    },
    [MATERIAL_UNITS.ML]: {
      factor: 0.0610237, // Milliliters to cubic inches
      unit: MATERIAL_UNITS.INCH3,
    },
  };

  let convertedQuantity = quantity;
  let convertedUnits = units;

  if (measurementSystem === MEASUREMENT_UNITS_SETTINGS.METRIC) {
    const conversion = toMetricConversion[units];
    if (conversion) {
      convertedQuantity = quantity * conversion.factor;
      convertedUnits = conversion.unit;
    }
  } else {
    const conversion = toImperialConversion[units];
    if (conversion) {
      convertedQuantity = quantity * conversion.factor;
      convertedUnits = conversion.unit;
    }
  }

  // Format the quantity for human readability
  convertedQuantity = formatQuantity(convertedQuantity);

  return { quantity: convertedQuantity, units: convertedUnits };
};

const formatQuantity = quantity => {
  if (quantity === 0) return 0;

  const absQuantity = Math.abs(quantity);

  // Determine the number of decimal places
  const decimalPlaces =
    absQuantity >= 0.01 ? 2 : Math.min(6, -Math.floor(Math.log10(absQuantity)) + 1);

  // Format the quantity
  return parseFloat(quantity.toFixed(decimalPlaces));
};
