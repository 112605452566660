import PropTypes from 'prop-types';
import React from 'react';

const Badge = ({ badgeStyle, children }) => (
  <span className={`float-right badge badge-${badgeStyle}`}>{children}</span>
);

Badge.propTypes = {
  badgeStyle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Badge.defaultProps = {
  badgeStyle: 'default',
};

export default Badge;
