import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  LINE_ITEM_COMPOSITION_TYPES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  PIECE_STATUS,
} from 'src/utils/constants';
import { getShortUuid, getUuid } from 'src/utils/url';

import Badge from '../../../components/badge';
import Header from '../../../components/header';
import NotFound from '../../../components/not-found';
import ScanButton from '../../../components/scan-button';
import Sentry from '../../../utils/sentry';
import userPropType from '../../../utils/user-prop-type';

const PartPieceStatusBadge = ({ piece }) => {

  let badgeContent;
  let badgeStyle;

  switch (piece.status) {
    case PIECE_STATUS.COMPLETE:
      badgeContent = 'Complete';
      badgeStyle = 'success';
      break;
    case PIECE_STATUS.CONFIRMED:
      badgeContent = 'Confirmed';
      badgeStyle = 'success';
      break;
    case PIECE_STATUS.NEW:
      badgeContent = 'Pending';
      badgeStyle = 'warning';
      break;
    case PIECE_STATUS.CANCELLED:
      badgeContent = 'Cancelled';
      badgeStyle = 'danger';
      break;
    case PIECE_STATUS.ERROR:
      badgeContent = 'Error';
      badgeStyle = 'danger';
      break;
    default:
      badgeContent = 'Processing';
      badgeStyle = 'warning';
      break;
  }

  return (<Badge badgeStyle={badgeStyle}>{badgeContent}</Badge>);
};

PartPieceStatusBadge.propTypes = {
  piece: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

/*
 * ONLY ASSEMBLY LINE ITEM SUPPORTED FOR NOW
 */
const LineItem = ({ user }) => {
  const { uuid: lineItemUUID } = useParams();
  const [pageState, setPageState] = useState({
    id: lineItemUUID,
    assemblyPartMeta: [],
    rootLineItem: null,
    product: null,
    pieces: [],
    prints: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const rootLineItem = await api.get(`${API_RESOURCES.LINE_ITEM}/${lineItemUUID}/`).json();
      if (!rootLineItem || rootLineItem.composition_type !== LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY) {
        setError('Failed to find line item.');
        return;
      }

      const [
        product,
        { resources: assemblyPartMeta },
      ] = await Promise.all([
        api.get(`${API_RESOURCES.PRODUCT}/${getUuid(rootLineItem.product)}/`).json(),
        api.get(`${API_RESOURCES.ASSEMBLY_PART_META}/`, {
          searchParams: {
            'filter[root_line_item]': rootLineItem.uri,
            'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
          },
        }).json(),
      ]);

      const lineItemUris = [rootLineItem.uri, ...assemblyPartMeta.map(({ part_line_item: partLineItemUri }) => partLineItemUri)];
      const { resources: pieces } = await api.get(`${API_RESOURCES.PIECE}/`, {
        searchParams: {
          'filter[line_item]': lineItemUris.join(','),
          'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
          'sort': 'current_step_position,status',
        },
      }).json();
      const pieceUris = pieces.map((p) => p.current_print).filter(Boolean);
      const { resources: prints } = pieceUris.length ? await api.get(`${API_RESOURCES.PRINT}/`, {
        searchParams: {
          'filter[uri]': pieceUris.join(','),
          'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
        },
      }).json() : { resources: [] };
      setPageState({
        id: lineItemUUID,
        assemblyPartMeta,
        rootLineItem,
        product,
        pieces,
        prints,
      });
    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError('Failed to find line item.');
      } else {
        setError(error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [lineItemUUID]);

  if (error) {
    return (
      <>
        <Header title='Not Found' user={user} />
        <main role='main' className='text-center'>
          <NotFound id={lineItemUUID} text={error} />
        </main>
      </>
    );
  }

  if (isLoading || !pageState.rootLineItem) {
    return (
      <>
        <Header title='Loading Line Item...' user={user} />
        <main role='main' className='text-center'>
          <div className='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true' />
          Loading...
        </main>
      </>
    );
  }


  // TODO Not heavy operation since it looks like static page and nothing expected to be changed
  //  in the DOM, but it can be moved into internal state or memoized
  // Only Assembly Line Item Piece and Assembly Part Pieces were loaded via API, so all except Assembly pieces are Part ones
  const rootPieces = pageState.pieces.filter(({ line_item: pieceLineItem }) => pieceLineItem === pageState.rootLineItem?.uri);
  // eslint-disable-next-line camelcase
  const partPieces = pageState.pieces.filter(({ line_item: pieceLineItem, remanufactured_to }) => {

    // eslint-disable-next-line camelcase
    if (remanufactured_to) {
      return;
    }
    return pieceLineItem !== pageState.rootLineItem.uri;
  });
  const goToAssemblyRun = (assemblyPieces) => {
    const rootPiece = assemblyPieces.length > 0 && assemblyPieces[0];
    const assemblyRunUri = pageState.prints.find((p) => p.piece === rootPiece?.uri)?.run;

    if (!assemblyRunUri) {
      addToast(
        `Assembly Run is not created for some reason. Contact your Bureau Manager if you have any questions.`,
        { appearance: 'error' },
      );

      Sentry.captureMessage(
        `Assembly Run is not created for piece ${getUuid(rootPiece.uri)} but all pieces for parts were completed.`,
        'error',
      );
      return;
    }

    navigate(`/run/${getUuid(assemblyRunUri)}`);
  };

  const getLinkToRun = () => {
    const rootPiece = rootPieces.length > 0 && rootPieces[0];
    const runUri = pageState.prints.find((p) => p.piece === rootPiece?.uri)?.run;

    return runUri ? `/run/${getUuid(runUri)}` : null;
  };

  const partsPluralized = pageState.assemblyPartMeta.length === 1 ? 'Part' : 'Parts';

  const areAllPiecesProcessed = partPieces.every(({ status }) => status === PIECE_STATUS.COMPLETE);
  return (
    <>
      <Header title='Product Assembly Parts List' user={user} />
      <main role='main'>
        <h2 className='header-margin'>
          Product: <span className='text-wrap badge badge-secondary'>{pageState.product.name}</span>
        </h2>

        <div className='h5'>
          Assembly
          <span className='badge badge-secondary'>{getShortUuid(pageState.rootLineItem.uri)}</span>
        </div>

        <h2 className='header-margin'>
          <span className='badge-circle'>{partPieces.length}</span>
          {` ${partsPluralized}`} in Product Assembly:
        </h2>

        <div className='mb-3'>
          {
            partPieces.length > 0 &&
            <ol className='clearfix'>
              {partPieces.map((piece) => (
                <li key={piece.uri}>
                  <span className='float-left'>{piece.name}
                    <span className='badge badge-secondary ml-1'>{getShortUuid(piece.uri)}</span>
                  </span>
                  <PartPieceStatusBadge piece={piece} />
                </li>
              ))}
            </ol>
          }
          {!partPieces.length && (
            <h4>No Parts for this Assembly yet.</h4>
          )}
        </div>

        <button
          disabled={areAllPiecesProcessed}
          type='submit'
          className='btn btn-primary btn-lg btn-block'
          onClick={() => goToAssemblyRun(rootPieces)}
        >
          Assemble
        </button>

        <button
          type='button'
          className='btn btn-outline-secondary btn-lg btn-block'
          onClick={() => navigate(getLinkToRun())}
        >
          Go to Current Runs
        </button>
        <ScanButton />
      </main>
    </>
  );
};


LineItem.propTypes = {
  user: userPropType,
};

export default LineItem;