import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import { api } from 'src/utils/api';
import { API_RESOURCES, LINE_ITEM_COMPOSITION_TYPES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

const Piece = ({ user }) => {
  const { uuid: pieceUUID } = useParams();
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchInitialData = async () => {
    try {
      const piece = await api.get(`${API_RESOURCES.PIECE}/${pieceUUID}/`).json();
      const currentPrint = piece.current_print ?
        await api.get(piece.current_print, { prefixUrl: false }).json()
        : null;
      const runUUID = currentPrint && getUuid(currentPrint.run);

      const isAssembly = piece.composition_type === LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY;
      const rootLineItemURI = isAssembly ? piece.line_item: undefined;
      if (rootLineItemURI) {
        navigate(`/line-item/${getUuid(rootLineItemURI)}`);
      }
      else if (runUUID) {
        navigate(`/run/${runUUID}`);
      } else {
        setError('Failed to find run.');
      }
    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError('Failed to find run.');
      } else {
        setError(error);
      }
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [pieceUUID]);

  if (error) {
    return (
      <>
        <Header title="Not Found" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pieceUUID} text={error} />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title="Loading" back="/scan" user={user} />
      <main role="main" className="text-center">
        <Loader inline text="piece" className="text-muted" />
      </main>
    </>
  );
};

Piece.propTypes = {
  user: userPropType.isRequired,
};
export default Piece;
