import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from 'src/components/header.jsx';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found.jsx';
import ScanButton from 'src/components/scan-button';
import { api } from 'src/utils/api';
import config from 'src/utils/config';
import { API_RESOURCES, LINE_ITEM_COMPOSITION_TYPES } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

/*
    Summary page is required for these purposes:
  * The users who already printed their QR Codes from the Traveler Pages have the URL to the Summary Page.
  * Having the direct link to the Run page (which was tried here: [sc-69561]
    is not an option as they will print the QR Codes with the static Run URLs rather than
    dynamic redirect to the latest, actual Run page based on the API data.
  * If needed, you can still modify the link (but saving the old access via the Summary Page),
    but the main concept is to save the ability for the user who scans the QR code (even old)
    to access the latest Run, not the static one.
*/

const SummaryPage = ({ user }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { line_item: lineItemUuid, copy } = Object.fromEntries(searchParams.entries()) ?? {};
  const [error, setError] = useState(undefined);

  // Fetch piece and print data and redirect to /run/[uuid] page if found
  // Otherwise show error page and scan button
  const redirectToRun = async () => {
    const lineItemPath = `line-item/${lineItemUuid}/`;

    try {
      // Step 1: Get the Pieces for the Line Item by the Copy (Piece "ordering" -> #) number.
      const { resources: pieces } = await api.get(
        `${API_RESOURCES.PIECE}/`,
        {
          searchParams: {
            'filter[copy]': copy,
            // Line item may already be deleted, but piece may still exist
            // (so filter by line item will still work)
            'filter[line_item]': `${config.apiHost}/${lineItemPath}`,
            'sort': '-created',
          },
        }
      ).json();

      if (pieces?.length === 0) {
        setError('Piece not found.');
        return;
      }

      // Step 2: Either find the Piece (if multiple) which has "current_print" URI or get the first Piece.
      const activePrintPiece = pieces.find((piece) => piece.current_print) ?? pieces[0];

      // Step 3: If the Piece has a "current_print" URI, send API GET for /print to get the current Print.
      const currentPrint = activePrintPiece.current_print ?
        await api.get(activePrintPiece.current_print, { prefixUrl: false }).json()
        : null;

      // Step 4: If we have the Current Print, get its Run URI.
      const runUUID = currentPrint && getUuid(currentPrint.run);

      const isAssembly = activePrintPiece.composition_type === LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY;
      const rootLineItemURI = isAssembly ? activePrintPiece.line_item: undefined;

      // Step 5: If line item is assembly, redirect to the Line Item page.
      if (rootLineItemURI) {
        navigate(`/line-item/${getUuid(rootLineItemURI)}`);
      }
      // If we have the Run URI, redirect to the Run page.
      else if (runUUID) {
        navigate(`/run/${runUUID}`);
      }
      // Otherwise, show Run error.
      else {
        setError('Failed to find run.');
      }

    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    void redirectToRun();
  }, [lineItemUuid]);

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={lineItemUuid} />
          <ScanButton />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title="Loading..." back="/scan" />
      <main role="main" className="text-center">
        <Loader inline text="piece" className="text-muted" />
      </main>
    </>
  );
};

SummaryPage.propTypes = {
  user: userPropType,
};

SummaryPage.defaultProps = {
  user: null,
};

export default SummaryPage;
