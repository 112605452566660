import { faArrowUpShortWide, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCoins, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StatusColorCode from 'src/components/status-color-dot';
import TruncatedTitle from 'src/components/TruncatedTitle';
import { PERMANENT_CONTAINER_STATUS_VERBOSE, STATUS_COLOR_CODE_TYPES } from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import routes from 'src/utils/routes';
import { getRouteURI, getUuid } from 'src/utils/url';

const PermanentContainerCardDetails = ({
  permanentContainer,
  location,
  subLocation,
  isError,
  units,
  customMainStyle,
  swapRightIconPosition,
  hideContainerStatus,
  locationsFetching,
}) => {
  const [locationShown, setLocationShown] = React.useState(false);
  if (!permanentContainer) return null;

  const { quantity: permanentContainerConvertedQuantity, units: permanentContainerConvertedUnit } =
    convertToUserUnits(permanentContainer.quantity, units);

  const isDisposableContainer = permanentContainer.disposable;

  if (isError) {
    return (
      <p className='error-loading-card-resource'>
        <FontAwesomeIcon icon={faExclamationCircle} className='d-block spacer-right danger-color' />
        An error occurred while loading the Containers Data.
      </p>
    );
  }

  const toggleLocation = () => setLocationShown(previous => !previous);

  const renderCardTitle = () => {
    if (isDisposableContainer) {
      return 'Disposable Container';
    }

    return (
      <Link
        to={getRouteURI(routes.permanentContainerDetails, {
          uuid: getUuid(permanentContainer.uri),
        })}
      >
        <TruncatedTitle
          inline
          title={permanentContainer.name}
          titleClassName='permanent-container-card-details-header-title font-medium mb0'
          maxTextLength={30}
          maxTextLengthMobile={20}
        />
      </Link>
    );
  };

  const quantityIcon = (
    <FontAwesomeIcon
      className={swapRightIconPosition ? 'spacer-left' : 'spacer-right'}
      icon={faArrowUpShortWide}
    />
  );

  const renderContainerStatus = () => (
    <div className='d-flex align-items-center'>
      <p className='mb0 d-flex align-items-center'>
        <StatusColorCode
          status={permanentContainer?.status}
          type={STATUS_COLOR_CODE_TYPES.PERMANENT_CONTAINER_STATUS}
          sizeClassName='small'
        />
      </p>
      <p className='mb0 spacer-left'>
        {PERMANENT_CONTAINER_STATUS_VERBOSE[permanentContainer?.status]}
      </p>
    </div>
  );

  const renderContainerBody = () => {
    if (!hideContainerStatus) {
      return (
        <>
          <div className='d-flex align-items-center justify-content-between mb8'>
            {renderContainerStatus()}
            <p className='mb0'>
              {!swapRightIconPosition && quantityIcon}
              {permanentContainerConvertedQuantity} {permanentContainerConvertedUnit}
              {swapRightIconPosition && quantityIcon}
            </p>
          </div>
          <div className='permanent-container-card-details-location'>
            <p className='mb0 badge badge-secondary' onClick={toggleLocation}>
              {!locationShown ? 'Show' : 'Hide'} Location
            </p>
          </div>
          {locationShown ? (
            <div
              style={{
                textAlign: 'left',
                marginTop: '10px',
              }}
            >
              <div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon className='spacer-right' icon={faLocationDot} />
                  <p className='mb0'>{location}</p>
                </div>
                <p className='mb0 badge' style={{ paddingLeft: 0, marginLeft: '19px' }}>
                  {subLocation}
                </p>
              </div>
            </div>
          ) : null}
        </>
      );
    }

    return locationsFetching ? (
      <Spinner />
    ) : (
      <div className='d-flex align-items-center justify-content-between mb8'>
        <div
          style={{
            textAlign: 'left',
            marginTop: '10px',
          }}
        >
          <div>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon className='spacer-right' icon={faLocationDot} />
              <TruncatedTitle
                title={location}
                titleClassName='mb0 font-medium'
                maxTextLength={25}
                maxTextLengthMobile={20}
              />
            </div>
            <TruncatedTitle
              title={subLocation}
              titleClassName='mb0'
              maxTextLength={35}
              style={{ paddingLeft: 0, marginLeft: '19px' }}
              maxTextLengthMobile={30}
            />
          </div>
        </div>
        <p className='mb0'>
          {!swapRightIconPosition && quantityIcon}
          {permanentContainerConvertedQuantity} {permanentContainerConvertedUnit}
          {swapRightIconPosition && quantityIcon}
        </p>
      </div>
    );
  };

  return (
    <div className='permanent-container-card-details' style={customMainStyle}>
      <div className='permanent-container-card-details-header'>
        <div className='d-flex align-items-center'>
          <FontAwesomeIcon className='spacer-right' icon={faCoins} />
          <p>{renderCardTitle()}</p>
        </div>
      </div>
      <div className='permanent-container-card-details-body'>{renderContainerBody()}</div>
    </div>
  );
};

PermanentContainerCardDetails.propTypes = {
  /* eslint-disable camelcase */
  permanentContainer: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    uri: PropTypes.string,
    disposable: PropTypes.bool,
    quantity: PropTypes.number,
  }),
  units: PropTypes.string.isRequired,
  location: PropTypes.string,
  subLocation: PropTypes.string,
  customMainStyle: PropTypes.shape({}),
  swapRightIconPosition: PropTypes.bool,
  isError: PropTypes.bool,
  hideContainerStatus: PropTypes.bool,
  locationsFetching: PropTypes.bool,
};

PermanentContainerCardDetails.defaultProps = {
  permanentContainer: null,
  location: null,
  subLocation: null,
  customMainStyle: {},
  swapRightIconPosition: false,
  isError: false,
  hideContainerStatus: false,
  locationsFetching: false,
};

export default PermanentContainerCardDetails;
